import { Body } from 'components/Text'
import { PROD_REFFERAL_URL } from 'constants/links'
import { type FC, type MouseEvent, type ReactNode, useCallback, useState } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

interface TooltipPosition {
	x: number
	y: number
}

type CopyType = 'text' | 'referralLink' | 'marketLink'

interface UseCopyToClipboardResult {
	copyToClipboard: (event: MouseEvent, text: string | undefined, type?: CopyType) => void
	CopyTooltip: FC
}

interface UseCopyToClipboardOptions {
	tooltipDuration?: number
	tooltipContent?: ReactNode
	tooltipOffset?: TooltipPosition
}

export const useCopyToClipboard = ({
	tooltipDuration = 1000,
	tooltipContent = 'Address Copied!',
	tooltipOffset = { x: 10, y: 10 },
}: UseCopyToClipboardOptions = {}): UseCopyToClipboardResult => {
	const [copiedStatus, setCopiedStatus] = useState<boolean>(false)
	const [tooltipPosition, setTooltipPosition] = useState<TooltipPosition | null>(null)

	const copyToClipboard = useCallback(
		(event: MouseEvent, text: string | undefined, type: CopyType = 'text') => {
			const x = event.clientX
			const y = event.clientY

			let contentToCopy: string | undefined

			const { protocol, hostname, port } = window.location

			switch (type) {
				case 'text': {
					contentToCopy = text?.trim()
					break
				}
				case 'referralLink': {
					if (text?.trim()) {
						contentToCopy = `${protocol}//${
							process.env.NEXT_PUBLIC_REF_URL_OVERRIDE === 'true' ? PROD_REFFERAL_URL : hostname
						}${port ? `:${port}` : ''}/?ref=${text.trim()}`
					}
					break
				}
				case 'marketLink': {
					if (text?.trim()) {
						contentToCopy = `${protocol}//${hostname}${port ? `:${port}` : ''}/market/?wallet=${text.trim()}`
					}
					break
				}
				default:
					return
			}

			if (!contentToCopy) return

			navigator.clipboard.writeText(contentToCopy).then(() => {
				setCopiedStatus(true)
				setTooltipPosition({ x, y })

				setTimeout(() => {
					setCopiedStatus(false)
					setTooltipPosition(null)
				}, tooltipDuration)
			})

			event.stopPropagation()
		},
		[tooltipDuration]
	)

	const CopyTooltip = () => {
		if (!copiedStatus || !tooltipPosition) return null

		return createPortal(
			<StyledTooltip
				top={`${tooltipPosition.y + tooltipOffset.y}px`}
				left={`${tooltipPosition.x + tooltipOffset.x}px`}
				padding="5px"
				borderRadius="8px"
			>
				<Body>{tooltipContent}</Body>
			</StyledTooltip>,
			document.body
		)
	}

	return { CopyTooltip, copyToClipboard }
}

const StyledTooltip = styled.div<{
	top?: string
	left?: string
	padding?: string
	borderRadius?: string
}>`
	position: fixed;
	top: ${(props) => props.top};
	left: ${(props) => props.left};
	padding: ${(props) => props.padding};
	border-radius: ${(props) => props.borderRadius};
	background: ${(props) => props.theme.colors.selectedTheme.button.fill};
	border: ${(props) => props.theme.colors.selectedTheme.border};
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	transition: opacity 0.3s;
	z-index: 200;
	opacity: 1;
`
