import { truncateAddress } from '@kwenta/sdk/utils'
import { type FC, type MouseEvent, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { useEnsAvatar, useEnsName } from 'wagmi'

import CopyCheckIcon from 'assets/svg/referrals/copy-check.svg'
import Button from 'components/Button'
import CountBadge from 'components/CountBadge'
import { FlexDivRowCentered } from 'components/layout/flex'
import { WatcherModeIcon } from 'sections/futures/WatcherMode/WatchOrConnectWallet'
import { setOpenModal } from 'state/app/reducer'
import { selectSubAccountsForAccount } from 'state/futures/selectors'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import {
	selectIsWatcherMode,
	selectSignerWallet,
	selectWatcherWallet,
} from 'state/wallet/selectors'

import { useCopyToClipboard } from 'hooks/useCopyToClipboard'
import ConnectionDot from '../ConnectionDot'
import NetworksSwitcher from '../NetworksSwitcher'

export const MobileWalletActions: FC = () => {
	const dispatch = useAppDispatch()
	const signerWallet = useAppSelector(selectSignerWallet)
	const watcherWallet = useAppSelector(selectWatcherWallet)
	const isWatcherMode = useAppSelector(selectIsWatcherMode)
	const subAccountList = useAppSelector(selectSubAccountsForAccount)

	const walletAddress = useMemo(
		() => (isWatcherMode ? watcherWallet : signerWallet),
		[isWatcherMode, watcherWallet, signerWallet]
	)
	const { data: ensName } = useEnsName({ address: walletAddress!, chainId: 1 })
	const { data: ensAvatar } = useEnsAvatar({ name: ensName ?? undefined, chainId: 1 })
	const truncatedWalletAddress = truncateAddress(walletAddress! ?? '')
	const { copyToClipboard, CopyTooltip } = useCopyToClipboard({
		tooltipContent: 'URL Copied!',
		tooltipOffset: { x: -60, y: -60 },
	})

	const walletLabel = useMemo(() => {
		return ensName || truncatedWalletAddress!
	}, [ensName, truncatedWalletAddress])

	const handleWalletButtonClick = useCallback(
		() =>
			isWatcherMode
				? dispatch(setOpenModal('watch_or_connect_wallet'))
				: dispatch(setOpenModal('manage_wallet')),
		[isWatcherMode, dispatch]
	)

	return (
		<Container>
			{!isWatcherMode && <NetworksSwitcher mobile />}
			<StyledButton
				mono
				noOutline
				isWatcherMode={isWatcherMode}
				onClick={handleWalletButtonClick}
				size="small"
			>
				{isWatcherMode ? (
					<WatcherModeIcon $active />
				) : ensAvatar ? (
					<StyledImage src={ensAvatar} alt={ensName || walletAddress!} width={16} height={16} />
				) : (
					<ConnectionDot />
				)}
				{walletLabel}
				{isWatcherMode && (
					<StyledCopyCheckIcon
						onClick={(event: MouseEvent) => copyToClipboard(event, walletAddress, 'marketLink')}
					/>
				)}
				{subAccountList.length > 0 && <CountBadge count={subAccountList.length} />}
			</StyledButton>
			<CopyTooltip />
		</Container>
	)
}

const StyledCopyCheckIcon = styled(CopyCheckIcon)`
	margin-left: 6px;
	cursor: pointer;
	color: ${(props) => props.theme.colors.selectedTheme.newTheme.text.primary};
`

const Container = styled(FlexDivRowCentered)`
	font-size: 12px;
`

const StyledButton = styled(Button)<{ isWatcherMode?: boolean }>`
	text-transform: lowercase;
	height: 41px;
	color: ${(props) =>
		props.isWatcherMode ? props.theme.colors.selectedTheme.watcherMode.active : ''};
`

const StyledImage = styled.img`
	border-radius: 50%;
	margin-right: 8px;
`

export default MobileWalletActions
