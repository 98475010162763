import { useChainModal } from '@rainbow-me/rainbowkit'
import type { FC } from 'react'
import styled, { css } from 'styled-components'

import ArbitrumIcon from 'assets/svg/providers/arbitrum.svg'
import AvalancheIcon from 'assets/svg/providers/avalanche.svg'
import BaseIcon from 'assets/svg/providers/base.svg'
import BinanceIcon from 'assets/svg/providers/binance.svg'
import EthereumIcon from 'assets/svg/providers/ethereum.svg'
import OptimismIcon from 'assets/svg/providers/optimism.svg'
import PolygonIcon from 'assets/svg/providers/polygon.svg'
import Button from 'components/Button'
import Connector from 'containers/Connector'

type NetworksSwitcherProps = {
	mobile?: boolean
}

const NetworksSwitcher: FC<NetworksSwitcherProps> = ({ mobile }) => {
	const { network } = Connector.useContainer()
	const { openChainModal } = useChainModal()
	const networkIcon = (prefixIcon: string) => {
		switch (prefixIcon) {
			case 'Polygon':
				return <PolygonIcon width={24} height={16} />
			case 'Arbitrum One':
			case 'Arbitrum Sepolia':
				return <ArbitrumIcon width={24} height={16} />
			case 'Ethereum':
				return <EthereumIcon width={24} height={16} />
			case 'Avalanche':
				return <AvalancheIcon width={24} height={16} />
			case 'BNB Smart Chain':
				return <BinanceIcon width={24} height={16} />
			case 'Base Sepolia':
			case 'Base':
				return <BaseIcon width={24} height={16} />
			default:
				return <OptimismIcon width={24} height={16} />
		}
	}

	return (
		<Container onClick={openChainModal} $mobile={mobile}>
			<StyledButton noOutline size="small" mono>
				{networkIcon(network?.name ?? '')}
			</StyledButton>
		</Container>
	)
}

export default NetworksSwitcher

const Container = styled.div<{ $mobile?: boolean }>`
	${(props) =>
		props.$mobile &&
		css`
			margin-right: 10px;
		`}
`

const StyledButton = styled(Button)`
	width: 41px;
	padding: 0;
`
