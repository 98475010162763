import type { PerpsProvider } from '@kwenta/sdk/types'
import { useRouter } from 'next/router'
import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import TradingRewardsIcon from 'assets/svg/app/gift.svg'
import { Body } from 'components/Text'
import { FlexDivCol, FlexDivRow } from 'components/layout/flex'
import { PROVIDER_MAINNET_CHAINS, PROVIDER_NAMES } from 'constants/network'
import ROUTES from 'constants/routes'
import { selectPerpsProvider } from 'state/futures/common/selectors'
import { useAppSelector } from 'state/hooks'

import Connector from 'containers/Connector'
import type { NetworkItem } from '../constants'

type FuturesDropDown = {
	networks: NetworkItem[]
	onDismiss: () => void
}

const MobileIntegrationsDropDown = memo(({ networks, onDismiss }: FuturesDropDown) => {
	const { t } = useTranslation()
	const provider = useAppSelector(selectPerpsProvider)
	const { switchToChain } = Connector.useContainer()
	const { push } = useRouter()

	const handleProviderSwitch = useCallback(
		(provider: PerpsProvider) => {
			switchToChain(PROVIDER_MAINNET_CHAINS[provider])
			push(ROUTES.Markets.Home(provider))
			onDismiss()
		},
		[onDismiss, push, switchToChain]
	)

	return networks.map(({ network, providers }) => {
		return (
			<Container key={network.name}>
				<NetworkNameRow>
					{network.icon}
					<Body weight="bold" size="medium" mono color="preview">
						{t(network.name).toUpperCase()}
					</Body>
				</NetworkNameRow>
				<FlexDivCol>
					{providers
						.filter((p) => p.enabled)
						.map((p) => (
							<ProviderContainer
								key={p.name}
								isActive={provider === p.name}
								onClick={() => handleProviderSwitch(p.name)}
							>
								<ProviderRow>
									{p.icon}
									<Body
										weight="bold"
										size="large"
										mono
										color={provider === p.name ? 'preview' : 'primary'}
									>
										{PROVIDER_NAMES[p.name]}
									</Body>
									{p.hasRewards && <TradingRewardsIcon />}
								</ProviderRow>
							</ProviderContainer>
						))}
				</FlexDivCol>
			</Container>
		)
	})
})

const ProviderContainer = styled(FlexDivCol)<{ isActive: boolean }>`
	width: 100%;
	height: 35px;
	justify-content: center;
	border-radius: 8px;
	padding: 0 20px;

	background-color: ${(props) =>
		props.isActive ? props.theme.colors.common.palette.neutral.n800 : 'transparent'};
`

const Container = styled(FlexDivCol)`
	gap: 10px;
	margin-bottom: 10px;
`

const NetworkNameRow = styled(FlexDivRow)`
	gap: 5px;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	bottom: 10px;
`

const ProviderRow = styled(FlexDivRow)`
	gap: 5px;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
`

export default MobileIntegrationsDropDown
